import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { BaseModel } from './base';
import { IUrlParam } from './users';
import { WebServiceHelper } from '../lib/webServiceHelper';
import { IRatings } from './impact';
import { PersonaTemplateIdEnum } from '../constants';
import { getLocalStorageItemWithExpiry } from '../lib/localStorage';

export const GenericApplicationErrorMessage = 'Please try applying again. If issues continue, please contact support@karmawallet.io.';
export const PersonaTemplateSource = 'embedded';

type PrivateFields =
  | '_applicationDecision'
  | '_awaitingApplicationDecision'
  | '_busy'
  | '_confirmationEmail'
  | '_currentPage'
  | '_email'
  | '_errorText'
  | '_donationsOptedIn'
  | '_openPeronaModal'
  | '_pageOneComplete'
  | '_pageTwoComplete'
  | '_pendingPageComplete'
  | '_personaAccountId'
  | '_providerKycFailure'
  | '_sscid'
  | '_sscidCreatedOn'
  | '_startConnection'
  | '_stripeClientSecret'
  | '_urlParams'
  | '_userId'
  | '_xType';

type KarmaCardLegalPrivateFields = '_busy' | '_legalText' | '_issuerStatement' | '_supportPhoneNumber' | '_initiateTransferStatement';

type KarmaCardBenefitsPrivateFields = '_busy' | '_benefits';

export const MaxDecisionWaitTime = 5000;

export type GetPersonaAccountDataResponse = {
  accountId: string;
  inquiryId: string;
  sessionToken: string;
};

export interface IKarmaCardLegalText {
  _id?: string;
  name?: string;
  text: string;
  createdOn?: string;
  lastModified?: string;
}

export interface IKarmaCardBenefitsGraphic {
  src: string;
  className: string;
}

export interface IKarmaCardBenefitsLearnMore {
  type: string;
  text?: string;
  toolTipSubText?: string;
  toolTipUrlText?: string;
  toolTipUrl?: string;
}

export interface IKarmaCardBenefits {
  _id?: string;
  createdOn?: string;
  lastModified?: string;
  header:string;
  title: string;
  description: string;
  graphic?:IKarmaCardBenefitsGraphic;
  learnMore?:IKarmaCardBenefitsLearnMore;
}

export enum ApplicationStates {
  failure = 'failure',
  pending = 'pending',
  success = 'success',
}

export enum IMarqetaErrorReasons {
  AddressIssue = 'AddressIssue',
  DateOfBirthIssue = 'DateOfBirthIssue',
  NameIssue = 'NameIssue',
  SSNIssue = 'SSNIssue',
  NoRecordFound = 'NoRecordFound',
  RiskIssue = 'RiskIssue',
  Denied_KYC = 'Denied KYC',
  OFACFailure = 'OFACFailure',
  Approved = 'Approved',
  Already_Registered = 'Already_Registered',
  FailedInternalKyc = 'FailedInternalKyc',
  ManualDecline = 'ManualDecline',
}

export interface IApplicationDecision {
  message: string;
  status: ApplicationStates;
  reason: IMarqetaErrorReasons;
  paymentData?: {
    client_secret?: string;
    url?: string;
  };
  acceptedDocuments?: string[];
  solutionText: string;
  internalKycTemplateId: string;
}

export enum CurrentPage {
  Error = 'error',
  PageTwo_CollectDocs = 'page-two-collect-docs',
  PageOne = 'page-one',
  Pending = 'pending',
  Success = 'success',
  AbandonedWithInfo = 'abandoned-with-info',
  ReturningAbandonedWithInfo = 'returning-abandoned-with-info',
  Abandoned = 'abandoned',
  Payment = 'payment',
  Declined = 'declined',
}

export const TemplateIdForPage = {
  [CurrentPage.PageOne]: PersonaTemplateIdEnum.dbVerification,
  [CurrentPage.PageTwo_CollectDocs]: PersonaTemplateIdEnum.KW5,
  [CurrentPage.Pending]: PersonaTemplateIdEnum.none,
  [CurrentPage.Success]: PersonaTemplateIdEnum.none,
  [CurrentPage.Error]: PersonaTemplateIdEnum.none,
  [CurrentPage.Abandoned]: PersonaTemplateIdEnum.none,
  [CurrentPage.AbandonedWithInfo]: PersonaTemplateIdEnum.none,
  [CurrentPage.ReturningAbandonedWithInfo]: PersonaTemplateIdEnum.none,
  [CurrentPage.Payment]: PersonaTemplateIdEnum.none,
  [CurrentPage.Declined]: PersonaTemplateIdEnum.none,
};

type FieldValue = {
  type: string;
  value: string | null;
};

type FieldData = {
  'address-street-1': FieldValue;
  'address-street-2': FieldValue;
  'address-city': FieldValue;
  'address-subdivision': FieldValue;
  'address-postal-code': FieldValue;
  'address-country-code': FieldValue;
  'selected-country-code': FieldValue;
  'social-security-number': FieldValue;
  'name-first': FieldValue;
  'name-middle': FieldValue;
  'name-last': FieldValue;
  birthdate: FieldValue;
  'email-address': FieldValue;
  'input-phone-number': FieldValue;
  'phone-number': FieldValue;
};

export type CompletedInquiry = {
  inquiryId: string;
  status: string;
  fields: FieldData;
};

export const ApplicationLocalStorageKey = {
  affiliateParam: 'affiliateParam',
  xTypeParam: 'xTypeParam',
  sscidCreatedOn: 'sscidCreatedOn',
  page: 'currentPage',
  email: 'applicantEmail',
} as const;
export type ApplicationLocalStorageKeyValues = typeof ApplicationLocalStorageKey[keyof typeof ApplicationLocalStorageKey];

export class ApplyForCardModel extends BaseModel {
  private _applicationDecision: IApplicationDecision = null;
  private _awaitingApplicationDecision = false;
  private _busy = false;
  private _confirmationEmail = '';
  private _currentPage = getLocalStorageItemWithExpiry(ApplicationLocalStorageKey.page) as CurrentPage || CurrentPage.PageOne;
  private _email = '';
  private _donationsOptedIn = false;
  private _startConnection = false;
  private _errorText = '';
  private _openPeronaModal = false;
  private _pageOneComplete = false;
  private _pageTwoComplete = false;
  private _pendingPageComplete = false;
  private _personaAccountId = '';
  private _providerKycFailure = false;
  private _urlParams: IUrlParam[] = [];
  private _sscid = '';
  private _sscidCreatedOn = '';
  private _stripeClientSecret = '';
  private _userId = '';
  private _xType = '';

  constructor() {
    super();
    makeObservable<ApplyForCardModel, PrivateFields>(this, {
      _applicationDecision: observable,
      _awaitingApplicationDecision: observable,
      _busy: observable,
      _confirmationEmail: observable,
      _currentPage: observable,
      _email: observable,
      _donationsOptedIn: observable,
      _personaAccountId: observable,
      _errorText: observable,
      _openPeronaModal: observable,
      _pageOneComplete: observable,
      _pageTwoComplete: observable,
      _pendingPageComplete: observable,
      _providerKycFailure: observable,
      _sscid: observable,
      _sscidCreatedOn: observable,
      _startConnection: observable,
      _stripeClientSecret: observable,
      _urlParams: observable,
      _userId: observable,
      _xType: observable,
      applicationDecision: computed,
      busy: computed,
      currentPage: computed,
      currentTemplateId: computed,
      email: computed,
      errorText: computed,
      stripeClientSecret: computed,
      setConfirmationEmail: action.bound,
      setCurrentPage: action.bound,
      setStripeClientSecret: action.bound,
      setCurrentPageFromApplicationDecision: action.bound,
      setEmail: action.bound,
      setUrlParams: action.bound,
      setSSCID: action.bound,
      setSSCIDCreatedOn: action.bound,
      setXType: action.bound,
    });
  }

  get personaAccountId() {
    return this._personaAccountId;
  }
  get busy() {
    return this._busy;
  }
  get awaitingApplicationDecision() {
    return this._awaitingApplicationDecision;
  }
  get openPersonaModal() {
    return this._openPeronaModal;
  }
  get pageOneComplete() {
    return this._pageOneComplete;
  }
  get pageTwoComplete() {
    return this._pageTwoComplete;
  }
  get pendingPageComplete() {
    return this._pendingPageComplete;
  }
  get providerKycFailure() {
    return this._providerKycFailure;
  }
  get applicationDecision() {
    return this._applicationDecision;
  }
  get confirmationEmail() {
    return this._confirmationEmail;
  }
  get currentPage() {
    return this._currentPage;
  }
  get email() {
    return this._email;
  }
  get stripeClientSecret() {
    return this._stripeClientSecret;
  }
  get errorText() {
    return this._errorText;
  }
  get startConnection() {
    return this._startConnection;
  }
  get currentTemplateId() {
    return TemplateIdForPage[this._currentPage];
  }
  get customInquiryData() {
    const email = this?._email?.trim() || '';

    return {
      email_address: email,
      applicationData: {
        email,
        userId: this._userId,
        sscid: this._sscid,
        xType: this._xType,
        urlParams: this._urlParams,
        sscidCreatedOn: this._sscidCreatedOn,
        donationOptedIn: this._donationsOptedIn,
      },
      source: PersonaTemplateSource,
    };
  }

  public loadPersonaAccountId = async () => {
    if (!this.email) return;
    const webServiceHelper = new WebServiceHelper();
    try {
      const result = await webServiceHelper.sendRequest<GetPersonaAccountDataResponse>({
        path: '/integrations/verification/account-id',
        method: 'POST',
        data: { email: this.email },
      });

      if (result.success) {
        runInAction(() => {
          this._personaAccountId = result.value.accountId;
        });
      } else {
        throw new Error('Error retrieving the user\'s persona account data');
      }
    } catch (error) {
      return '';
    }
  };

  public getLatestApplicationDecision = async (setPageFromDecision = false, autoloadModal = false) => {
    if (!this.email) return;
    const webServiceHelper = new WebServiceHelper();

    try {
      const result = await webServiceHelper.sendRequest<IApplicationDecision>({
        path: '/karma-card/application-status',
        method: 'POST',
        data: { email: this.email },
      });

      if (!result.success) {
        throw new Error();
      }

      if (!result.value || !result.value.status) {
        throw new Error();
      }

      if (result.value?.paymentData?.client_secret) {
        this.setStripeClientSecret(result.value.paymentData.client_secret);
      }

      if (setPageFromDecision) {
        this.setAwaitingApplicationDecision(true);
        this.setCurrentPageFromApplicationDecision(result.value, autoloadModal);
      } else {
        this.setApplicationDecision(result.value);
      }
    } catch (error) {
      throw new Error('Error retrieving a decision for the application');
    }
  };

  public setStateFromFailedApplicationDecision = async (applicationDecision: IApplicationDecision, autoloadModal: boolean) => {
    this.setPageOneComplete(true);
    if (applicationDecision.reason === IMarqetaErrorReasons.ManualDecline) {
      this.setPageTwoComplete(true);
      if (this.currentPage !== CurrentPage.PageTwo_CollectDocs) this.setCurrentPage(CurrentPage.Declined);
      return;
    }
    
    switch (applicationDecision.internalKycTemplateId) {
      case PersonaTemplateIdEnum.KW5:
        this.setPageTwoComplete(true);
        if (this.currentPage !== CurrentPage.Pending) this.setCurrentPage(CurrentPage.Pending);
        break;
      case PersonaTemplateIdEnum.dbVerification:
        this.setCurrentPage(CurrentPage.PageTwo_CollectDocs);
        // open the modal for additional document collection
        if (!this.openPersonaModal && autoloadModal) this.setOpenPersonaModal(true);
        break;
      default:
        // didn't come from a template ??? Error Page?
        break;
    }
  };

  public setStateFromPendingApplicationDecision = async (applicationDecision: IApplicationDecision, autoloadModal: boolean) => {
    this.setPageOneComplete(true);
    switch (applicationDecision.internalKycTemplateId) {
      case PersonaTemplateIdEnum.KW5:
        this.setPageTwoComplete(true);
        if (this.currentPage !== CurrentPage.PageTwo_CollectDocs) this.setCurrentPage(CurrentPage.PageTwo_CollectDocs);
        break;
      case PersonaTemplateIdEnum.dbVerification:
        this.setCurrentPage(CurrentPage.PageOne);
        // open the modal for additional document collection
        if (!this.openPersonaModal && autoloadModal) this.setOpenPersonaModal(true);
        break;
      default:
        // didn't come from a template ??? Error Page?
        break;
    }
  };

  public setStateFromSuccessfulApplicationDecision = async (applicationDecision: IApplicationDecision) => {
    this.setPageOneComplete(true);
    this.setPageTwoComplete(true);

    if (applicationDecision?.paymentData?.client_secret) {
      this.setStripeClientSecret(applicationDecision.paymentData.client_secret);
      if (this.currentPage !== CurrentPage.Payment) {
        this.setCurrentPage(CurrentPage.Payment);
      }
    } else {
      this.setPendingPageComplete(true);
      if (this.currentPage !== CurrentPage.Success) this.setCurrentPage(CurrentPage.Success);
    }
  };

  public setCurrentPageFromApplicationDecision = async (applicationDecision: IApplicationDecision, autoloadModal: boolean) => {
    this.setApplicationDecision(applicationDecision);

    switch (applicationDecision.status) {
      case ApplicationStates.success:
        this.setStateFromSuccessfulApplicationDecision(applicationDecision);
        break;
      case ApplicationStates.failure:
        this.setStateFromFailedApplicationDecision(applicationDecision, autoloadModal);
        break;
      case ApplicationStates.pending:
        this.setStateFromPendingApplicationDecision(applicationDecision, autoloadModal);
        break;
    }
    this.setAwaitingApplicationDecision(false);
  };

  static checkIfEmailAvailable = async (email: string) => {
    const webServiceHelper = new WebServiceHelper();
    const result = await webServiceHelper.sendRequest<IRatings>({
      path: '/user/check-email',
      method: 'POST',
      data: { email },
    });

    return result;
  };

  setProviderKycFailure = (data: boolean) => {
    runInAction(() => (this._providerKycFailure = data));
  };
  setConfirmationEmail(data: string) {
    runInAction(() => (this._confirmationEmail = data));
  }
  setCurrentPage(data: CurrentPage) {
    runInAction(() => (this._currentPage = data));
  }
  setEmail(data: string) {
    if (!data || data === this._email) return;
    runInAction(() => (this._email = data));
  }
  setDonationsOptedIn(data: boolean) {
    runInAction(() => (this._donationsOptedIn = data));
  }
  setUrlParams(data: IUrlParam[]) {
    runInAction(() => (this._urlParams = data));
  }
  setSSCID(data: string) {
    runInAction(() => (this._sscid = data));
  }
  setSSCIDCreatedOn(data: string) {
    runInAction(() => (this._sscidCreatedOn = data));
  }
  setApplicationDecision(data: IApplicationDecision) {
    runInAction(() => (this._applicationDecision = data));
  }
  setXType(data: string) {
    runInAction(() => (this._xType = data));
  }
  setUserId(data: string) {
    runInAction(() => (this._userId = data));
  }
  setAwaitingApplicationDecision(data: boolean) {
    runInAction(() => (this._awaitingApplicationDecision = data));
  }
  setStripeClientSecret = (data: string) => {
    runInAction(() => this._stripeClientSecret = data);
  };
  setOpenPersonaModal(data: boolean) {
    runInAction(() => (this._openPeronaModal = data));
  }
  setPageOneComplete(data: boolean) {
    runInAction(() => (this._pageOneComplete = data));
  }
  setPendingPageComplete(data: boolean) {
    runInAction(() => (this._pendingPageComplete = data));
  }
  setPageTwoComplete(data: boolean) {
    runInAction(() => (this._pageTwoComplete = data));
  }
  setStartConnection(data: boolean) {
    runInAction(() => (this._startConnection = data));
  }

  public restartSocketConnection = () => {
    this.setStartConnection(false);
    this.setStartConnection(true);
  };
}

export class KarmaCardLegalModel extends BaseModel {
  private _busy = false;
  private _legalText: IKarmaCardLegalText[] = [];
  private _issuerStatement: IKarmaCardLegalText = null;
  private _supportPhoneNumber: IKarmaCardLegalText = null;
  private _initiateTransferStatement: IKarmaCardLegalText = null;
  private _issuerCashbackStatement: IKarmaCardLegalText = null;

  constructor() {
    super();
    makeObservable<KarmaCardLegalModel, KarmaCardLegalPrivateFields>(this, {
      _busy: observable,
      _legalText: observable,
      _issuerStatement: observable,
      _supportPhoneNumber: observable,
      _initiateTransferStatement: observable,
      busy: computed,
      legalText: computed,
      getLegalText: action.bound,
      createLegalText: action.bound,
      editLegalText: action.bound,
      issuerStatement: computed,
      supportPhoneNumber: computed,
      initiateTransferStatement: computed,
      issuerCashbackStatement: computed,
    });
  }

  get busy() {
    return this._busy;
  }
  get legalText() {
    return this._legalText;
  }
  get issuerStatement() {
    return this._issuerStatement?.text;
  }
  get supportPhoneNumber() {
    return this._supportPhoneNumber?.text;
  }
  get initiateTransferStatement() {
    return this._initiateTransferStatement?.text;
  }
  get issuerCashbackStatement() {
    return this._issuerCashbackStatement?.text;
  }

  public editLegalText = async (data: IKarmaCardLegalText, id: string) => {
    if (this._busy) return;
    runInAction(() => (this._busy = true));

    const result = await this.webServiceHelper.sendRequest<IKarmaCardLegalText>({
      path: `/admin/karma-card/legal-text/${id}`,
      method: 'PUT',
      data,
    });

    if (result.success) {
      runInAction(() => {
        const index = this._legalText.findIndex((legalText) => legalText._id === data._id);
        this._legalText[index] = result.value;
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });
      throw new Error(result.error);
    }
  };

  public createLegalText = async (data: IKarmaCardLegalText) => {
    if (this._busy) return;
    runInAction(() => (this._busy = true));

    const result = await this.webServiceHelper.sendRequest<IKarmaCardLegalText>({
      path: '/admin/karma-card/legal-text',
      method: 'POST',
      data,
    });

    if (result.success) {
      runInAction(() => {
        this._legalText.push(result.value);
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });
      throw new Error(result.error);
    }
  };

  public getLegalText = async () => {
    if (this._busy) return;
    runInAction(() => (this._busy = true));

    const result = await this.webServiceHelper.sendRequest<IKarmaCardLegalText[]>({
      path: '/karma-card/legal-text',
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._legalText = result.value;
        for (const lt of result.value) {
          switch (lt.name) {
            case 'Issuer Statement':
              this._issuerStatement = lt;
              break;
            case 'Issuer Cashback Statement':
              this._issuerCashbackStatement = lt;
              break;
            case 'Support Phone Number':
              this._supportPhoneNumber = lt;
              break;
            case 'Initiate Transfer Statement':
              this._initiateTransferStatement = lt;
              break;
            default:
              break;
          }
        }
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });
      throw new Error(result.error);
    }
  };
}

export class KarmaCardBenefitsModel extends BaseModel {
  private _busy = false;
  private _benefits: Record<string, IKarmaCardBenefits[]> = {};

  constructor() {
    super();
    makeObservable<KarmaCardBenefitsModel, KarmaCardBenefitsPrivateFields>(this, {
      _busy: observable,
      _benefits: observable,
      busy: computed,
      benefits: computed,
    });
  }

  get busy() {
    return this._busy;
  }
  get benefits(){
    return this._benefits;
  }

  public getBenefits = async () => {
    if (this._busy) return;
    runInAction(() => (this._busy = true));

    const result = await this.webServiceHelper.sendRequest<IKarmaCardBenefits[]>({
      path: '/karma-card/benefits',
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._benefits = this.groupByHeader(result.value);
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });
      throw new Error(result.error);
    }
  };

  private groupByHeader(data: IKarmaCardBenefits[]): Record<string, IKarmaCardBenefits[]>{
    return data.reduce((acc, currentBenefit) => {
      // Get the category key for the current item
      const header = currentBenefit.header;
  
      // If the category doesn't exist in the accumulator, create it as an array
      if (!acc[header]) {
        acc[header] = [];
      }

      // Add the current item to the respective category
      acc[header].push(currentBenefit);
  
      return acc;
    }, {} as Record<string, IKarmaCardBenefits[]>);
  }
}
